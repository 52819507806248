import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';

const ScoreBoard = ({data}) => {
const [innoneshow, setinnoneshow] = useState('innoneshow')



  return (
    <Accordion defaultActiveKey="0" className='scorboard-acc'>
    <Accordion.Item eventKey="0">
      <Accordion.Header>
        <label>{data?.innings1?.teamName}</label>
        <ul>
            <li>Run Rate: {data?.innings1?.runRate}</li>
            <li><b>{data?.innings1?.total}/{data?.innings1?.wickets} ({data?.innings1?.overs})</b></li>
            <li><i className="fas fa-angle-down"></i></li>
        </ul>
      </Accordion.Header>
      <Accordion.Body>
            <div className='table-reponsive'>
              
                <table className='scorboard-table'>
                  <thead onClick={()=>setinnoneshow(!innoneshow)}>
                  <th >Batter </th>
                    <th >R </th>
                    <th >B </th>
                    <th >4s </th>
                    <th >6s </th>
                    <th >SR </th>
                  </thead>
                  {innoneshow &&
                  <tbody>
                    {data?.innings1?.batting.map((item, i)=>{
                      return (
                        <tr key={i}>
                      <td>
                        <h5>{item?.firstName} {item?.lastName} {item?.isOut == '0' && <span style={{color:'red'}}>*</span>} {item?.isOut == '0' || item?.isOut == '' ?  null : <span className='howout'>({item?.howOut})</span>}</h5>
                        <h6>{item?.outStringNoLink}</h6>
                      </td>
                      <td>{item?.ballsFaced == 0 && item?.runsScored == 0 ? '--' : item?.runsScored}</td>
                      <td>{item?.ballsFaced == 0 && item?.runsScored == 0 ? '--' : item?.ballsFaced}</td>
                      <td>{item?.ballsFaced == 0 && item?.runsScored == 0 ? '--' : item?.fours}</td>
                      <td>{item?.ballsFaced == 0 && item?.runsScored == 0 ? '--' : item?.sixers}</td>
                      <td>{item?.ballsFaced == 0 && item?.runsScored == 0 ? '--' : parseFloat((item?.runsScored / item?.ballsFaced) * 100).toFixed(0)} </td>
                    </tr>
                      )
                       } )}
                    
                      
                  </tbody>
}



                </table>
                <ul className='d-flex my-2 fallwk'>
                      <li><b>Extras:</b></li>
                      <li className='ml-3'><b>{data?.innings1?.extras.b + data?.innings1?.extras.lb + data?.innings1?.extras.nb + data?.innings1?.extras.pn + data?.innings1?.extras.wd}</b> ( {`b ${data?.innings1?.extras.b}, lb ${data?.innings1?.extras.lb}, nb ${data?.innings1?.extras.nb}, pn ${data?.innings1?.extras.pn}, wd ${data?.innings1?.extras.wd}`})</li>
                    </ul>
                    <ul className='d-flex my-2 fallwk'>
                    <li><b>Fall of wickets:</b></li>
                        {data?.innings1?.fallOfWickets.map((item, index) =>{
                          return (
                            <li>[{item?.total} - <b>{item?.playerName}</b>],</li>
                          )
                        })}
                      </ul>
                <table className='scorboard-table'>
                  <thead>
                  <th >Bowling </th>
                    <th >O </th>
                    <th >M </th>
                    <th >R </th>
                      <th>W</th>
                    <th >NB </th>
                    <th >WD </th>
                    <th>ECO</th>
                  </thead>
              
                  <tbody>
                    {data?.innings1?.bowling.map((item, i)=>{
                      var overs = Math.floor(item?.balls / 6);
                      var remainingBalls = item?.balls % 6;
                      var result = overs + "." + remainingBalls
                      return (
                        <tr key={i}>
                      <td>
                        <h5>{item?.firstName} {item?.lastName} </h5>
                  
                      </td>
                      <td>{result}</td>
                      <td>{item?.maidens}</td>
                      <td>{item?.runs}</td>
                      <td>{item?.wickets}</td>
                      <td>{item?.noBalls}</td>
                      <td>{item?.wides} </td>
                      <td>{(item?.runs/result).toFixed(2)}</td>
                    </tr>
                      )
                       } )}
                    
                 
                  </tbody>

                </table>
            </div>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="1">
      <Accordion.Header>
        <label>{data?.innings2?.teamName}</label>
        <ul>
            <li>Run Rate: {data?.innings2?.runRate}</li>
            <li><b>{data?.innings2?.total}/{data?.innings2?.wickets} ({data?.innings2?.overs})</b></li>
            <li><i className="fas fa-angle-down"></i></li>
        </ul>
      </Accordion.Header>
      <Accordion.Body>
            <div className='table-reponsive'>
              
                <table className='scorboard-table'>
                  <thead>
                  <th >Batting </th>
                    <th >R </th>
                    <th >B </th>
                    <th >4s </th>
                    <th >6s </th>
                    <th >SR </th>
                  </thead>
                  <tbody>
                    {data?.innings2?.batting.map((item, i)=>{
                      return (
                        <tr>
                      <td>
                        <h5>{item?.firstName} {item?.lastName} {item?.isOut == '0' && <span style={{color:'red'}}>*</span>} {item?.isOut == '0' || item?.isOut == '' ?  null : <span className='howout'>({item?.howOut})</span>}</h5>
                        <h6>{item?.outStringNoLink}</h6>
                      </td>
                      <td>{item?.ballsFaced == 0 && item?.runsScored == 0 ? '--' : item?.runsScored}</td>
                      <td>{item?.ballsFaced == 0 && item?.runsScored == 0 ? '--' : item?.ballsFaced}</td>
                      <td>{item?.ballsFaced == 0 && item?.runsScored == 0 ? '--' : item?.fours}</td>
                      <td>{item?.ballsFaced == 0 && item?.runsScored == 0 ? '--' : item?.sixers}</td>
                      <td>{item?.ballsFaced == 0 && item?.runsScored == 0 ? '--' : parseFloat((item?.runsScored / item?.ballsFaced) * 100).toFixed(0)} </td>
                    </tr>
                      )
                       } )}
                    
                 
                  </tbody>
                  
                </table>
                <ul className='d-flex my-2 fallwk'>
                      <li><b>Extras:</b></li>
                      <li className='ml-3'><b>{data?.innings2?.extras.b + data?.innings2?.extras.lb + data?.innings2?.extras.nb + data?.innings2?.extras.pn + data?.innings2?.extras.wd}</b> ( {`b ${data?.innings2?.extras.b}, lb ${data?.innings2?.extras.lb}, nb ${data?.innings2?.extras.nb}, pn ${data?.innings2?.extras.pn}, wd ${data?.innings2?.extras.wd}`})</li>
                    </ul>
                    <ul className='d-flex my-2 fallwk'>
                    <li><b>Fall of wickets:</b></li>
                        {data?.innings2?.fallOfWickets.map((item, index) =>{
                          return (
                            <li>[{item?.total} - <b>{item?.playerName}</b>],</li>
                          )
                        })}
                      </ul>
                <table className='scorboard-table'>
                  <thead>
                  <th >Bowling </th>
                    <th >O </th>
                    <th >M </th>
                    <th >R </th>
                      <th>W</th>
                    <th >NB </th>
                    <th >WD </th>
                    <th>ECO</th>
                  </thead>
              
                  <tbody>
                    {data?.innings2?.bowling.map((item, i)=>{
                      var overs = Math.floor(item?.balls / 6);
                      var remainingBalls = item?.balls % 6;
                      var result = overs + "." + remainingBalls
                      return (
                        <tr>
                      <td>
                        <h5>{item?.firstName} {item?.lastName} </h5>
                  
                      </td>
                      <td>{result}</td>
                      <td>{item?.maidens}</td>
                      <td>{item?.runs}</td>
                      <td>{item?.wickets}</td>
                      <td>{item?.noBalls}</td>
                      <td>{item?.wides} </td>
                      <td>{(item?.runs/result).toFixed(2)}</td>
                    </tr>
                      )
                       } )}
                    
                 
                  </tbody>
                 
                </table>
            </div>
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
  )
}

export default ScoreBoard