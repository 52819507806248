import React, { useEffect, useState } from "react";
import LoginHeader from "../common/LoginHeader";
import DashboardMenu from "../common/DashboardMenu";
import { IMAGE } from "../../../utils/Theme";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ApiConnection, { BaseUrl } from '../../../utils/ApiConnection';
import Loader from '../../../utils/Loader';
import moment from "moment";
import { useSelector } from "react-redux";

const CreditHistory = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [loading, setloading] = useState(false)
    const [history, sethistory] = useState([])

    const fetchReducer = useSelector((state) => state.fccDataflowreducer)

    const GetCreditscore = async ()=>{
        setloading(true)
        try {
            const  response = await ApiConnection.get(`credit-points`)
            if(response?.status == 200){
                setloading(false)
                sethistory(response?.data?.data?.list)
               
            } else{
                setloading(false)
            }  
        } catch(err){
            setloading(false) 
        }
    }

    useEffect(() => {
        GetCreditscore()
    },[])

  return (
    <>
           {loading && <Loader/>}
    <LoginHeader title="Credit History" />
     <DashboardMenu />
       <div className="container">
            <div className="dashboard-panel p-5">
            <div className="d-box text-right mb-4">
                  <h3>
                  <img src={IMAGE.credit_icon} alt="d-icon"/> Credit Balance : <b>{fetchReducer?.user?.available_credit_points
}</b>
                  </h3>
                </div>
                <div className="withdraw-history">
                    <div className="withdraw-history-form row align-items-center mb-4">
                        <div className="col-auto">
                            <label>From Date:</label>
                        </div>
                        <div className="col-lg-2">
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" />
                        </div>
                        <div className="col-auto">
                            <label>To Date:</label>
                        </div>
                        <div className="col-lg-2">
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" />
                        </div>
                        <div className="col-lg-2">
                            <button className="bluesolidBtn">Search</button>
                        </div>
                        <div className="col-lg-3">
                            <button className="csvBtn"><i className="fas fa-download"></i>  Download Excel</button>
                        </div>
                    </div>
                    {history&&history.length > 0 ?
                    <div className="table-responsive">
                        <table className="history-table">
                            <thead>
                                <th>Particulars</th>
                                <th>Credits</th>
                                <th>Date</th>
                            </thead>
                            <tbody>
                            {/* {item?.type == 'D' ?<sup style={{color:'red'}}>Dr</sup> : <sup style={{color:'green'}}>Cr</sup>} */}
                                {history&&history.map((item, index)=>{
                                    return (
                                        <tr key={index}>
                                            <td>{item?.comment}</td>
                                            <td> {item?.type == 'D' ?<span style={{color:'red', fontSize:'20px'}}>-</span> : <span style={{color:'green', fontSize:'20px'}}>+</span>}  <b style={{color:item?.type == 'D' ? 'red': 'green'}}>{item?.credit}</b></td>
                                            <td>{moment(item?.updated_at).format("DD-MM-YYYY")}</td>
                                        </tr>
                                    )
                                })}
                             
                            </tbody>
                        </table>
                    </div>

                    :
                    <h5>No credit history</h5>
                            }
                </div>
        
            </div>
         </div>
   </>
  )
}

export default CreditHistory