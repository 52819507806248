import React, { useEffect, useState } from "react";
import LoginHeader from "../common/LoginHeader";
import DashboardMenu from "../common/DashboardMenu";
import { IMAGE } from "../../../utils/Theme";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ApiConnection, { BaseUrl } from '../../../utils/ApiConnection';
import Loader from '../../../utils/Loader';
import moment from "moment";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const Wallet = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [loading, setloading] = useState(false)
    const [history, sethistory] = useState([])

    const fetchReducer = useSelector((state) => state.fccDataflowreducer)

    const GetCreditscore = async ()=>{
        setloading(true)
        try {
            const  response = await ApiConnection.get(`get-member-winning-amount?type=C`)
            if(response?.status == 200){
                setloading(false)
                sethistory(response?.data?.data?.data)
               
            } else{
                setloading(false)
            }  
        } catch(err){
            setloading(false) 
        }
    }

    useEffect(() => {
        GetCreditscore()
    },[])


  return (
    <>
    {loading && <Loader/>}
<LoginHeader title="Wallet History" />
 <DashboardMenu />
   <div className="container">
        <div className="dashboard-panel p-4">
        <div className="d-box text-right mb-4">
       
                  <h3>
                 Balance : <b>$ {fetchReducer?.user?.available_credit_amount
}</b>     <NavLink to="/withdraw" className="outline-btn">Withdraw Fund</NavLink>
                  </h3>
                </div>
            <div className="withdraw-history">
                
                <div className="table-responsive">
                    <table className="history-table">
                        <thead>
                            <th>Particulars</th>
                            <th>Date</th>
                            <th>Amount</th>
                        </thead>
                        <tbody>
                            {history&&history.map((item, i)=>{
                                return (
                                    <tr key={i}>
                                    <td>{item?.game_name}</td>
                                    <td>{item?.updated_at == null ? 'N/A' : moment(item?.updated_at).format("DD-MM-YYYY")}</td>
                                    <td>$ {item?.amount}</td>
                                </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div>
            </div>
    
        </div>
     </div>
</>
  )
}

export default Wallet