import React, { useState } from "react";
import LoginHeader from "../common/LoginHeader";
import DashboardMenu from "../common/DashboardMenu";
import { IMAGE } from "../../../utils/Theme";

const ChangePasswors = () => {
  return (
    <>
    <LoginHeader title="Change Password" />
     <DashboardMenu />
       <div className="container">
            <div className="dashboard-panel p-5">
                <div className="profile-panel">
                   
                    <div className="row">

                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>Old Password</label>
                                <input type="password" className="form-control" placeholder="XXXX"/>
                            </div>
                        
                        </div>
                        
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>New Password</label>
                                        <input type="password" className="form-control" placeholder="XXXX"/>
                                    </div>
                                
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Confirm Password</label>
                                        <input type="password" className="form-control" placeholder="XXXX"/>
                                    </div>
                                
                                </div>
                               
                                <div className="col-lg-12">
                                <button className="solid-btn">Update</button>
                        </div>
                                </div>
                            </div>
               
        
            </div>
         </div>
   </>
  )
}

export default ChangePasswors