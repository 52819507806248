import React, { useEffect, useState } from 'react'
import DashboardHeader from "../common/DashboardHeader";
import AdminMenu from "../common/AdminMenu";
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../../utils/Loader';
import ApiConnection from '../../../utils/ApiConnection';

const AdminChangePassword = () => {
    const [loading, setloading] = useState(false)
    const [oldpassword, setoldpassword] = useState("")
    const [newpassword, setnewpassword] = useState("")
    const [confirmpassword, setconfirmpassword] = useState("")

    let navigate = useNavigate()

    const SubmitHandler = async () =>{
        if(oldpassword == ''){
            toast.error("Existing password is required");
          } else if(newpassword == ''){
            toast.error("New Password is required");
          } else if(newpassword !== confirmpassword){
            toast.error("Confirm Password does not match");
          } else {
            setloading(true)
            try{
                var FormData = require('form-data');
                var data = new FormData();
                data.append('existing_password', oldpassword);
                data.append('password', newpassword);
                data.append('c_password', confirmpassword);
                const response = await ApiConnection.post(`change-password`, data);
                if(response.status === 200){
                    setloading(false)
                    toast.success(response?.data?.message);
                    navigate("/admin/dashboard")
                } else {
                    setloading(false)
                }

            } catch(err){
                setloading(false)
                if(err.response.status === 500){
                      toast.error(err.response?.data?.message);   
                  
                }
        }
          }
    }



  return (
    <div>
          {loading && <Loader/>}
    <DashboardHeader title="Change Password" />
    <AdminMenu />
    <div className="container">
      <div className="dashboard-panel custom-table">

           <div className='row'>
                <div className='col-lg-6'>
                    <div className='form-group'>
                        <label>Existing Password</label>
                        <input type="password" className="form-control" placeholder="XXXXX"
                          value={oldpassword}  
                          onChange={(e)=>setoldpassword(e.target.value)}
                        />
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='form-group'>
                        <label>New Password</label>
                        <input type="password" className="form-control" placeholder="XXXXX"
                        value={newpassword}  
                        onChange={(e)=>setnewpassword(e.target.value)}
                        />
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='form-group'>
                        <label>Confirm Password</label>
                        <input type="password" className="form-control"placeholder="XXXXX"
                        value={confirmpassword}  
                        onChange={(e)=>setconfirmpassword(e.target.value)}
                        />
                    </div>
                </div>
                <div className='col-lg-12'>
                    <div className='form-group'>
                        <button className='btn btn-success btn-lg' onClick={SubmitHandler}>Change</button>
                    </div>
                </div>
           </div>
    
      </div>
      </div>
        
    </div>
  )
}

export default AdminChangePassword