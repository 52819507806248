import React, { useEffect, useState } from "react";
import LoginHeader from "../common/LoginHeader";
import DashboardMenu from "../common/DashboardMenu";
import { IMAGE } from "../../../utils/Theme";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ApiConnection, { BaseUrl } from '../../../utils/ApiConnection';
import Loader from '../../../utils/Loader';
import moment from "moment";
import { useSelector } from "react-redux";

const EarningHistory = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [loading, setloading] = useState(false)
    const [history, sethistory] = useState([])

    const fetchReducer = useSelector((state) => state.fccDataflowreducer)

    const GetCreditscore = async ()=>{
        setloading(true)
        try {
            const  response = await ApiConnection.get(`get-member-winning-amount?type=C`)
            if(response?.status == 200){
                setloading(false)
                sethistory(response?.data?.data?.data)
               
            } else{
                setloading(false)
            }  
        } catch(err){
            setloading(false) 
        }
    }

    useEffect(() => {
        GetCreditscore()
    },[])


  return (
    <>
        {loading && <Loader/>}
    <LoginHeader title="Winning History" />
     <DashboardMenu />
       <div className="container">
            <div className="dashboard-panel p-5">
                <div className="withdraw-history">
                    <div className="withdraw-history-form row align-items-center mb-4">
                        <div className="col-auto">
                            <label>From Date:</label>
                        </div>
                        <div className="col-lg-2">
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" />
                        </div>
                        <div className="col-auto">
                            <label>To Date:</label>
                        </div>
                        <div className="col-lg-2">
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" />
                        </div>
                        <div className="col-lg-2">
                            <button className="bluesolidBtn">Search</button>
                        </div>
                        <div className="col-lg-3">
                            <button className="csvBtn"><i className="fas fa-download"></i>  Download Excel</button>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="history-table">
                            <thead>
                                <th>Fantasy Game</th>
                                <th>Particulars</th>
                                <th>Date</th>
                                <th>Winning Amount</th>
                            </thead>
                            <tbody>
                                {history&&history.map((item, i)=>{
                                    return (
                                        <tr key={i}>
                                        <td>{item?.game_name}</td>
                                        <td>{item?.type == 'D' ? item?.comments : item?.game_name}</td>
                                        <td>{item?.updated_at == null ? 'N/A' : moment(item?.updated_at).format("DD-MM-YYYY")}</td>
                                        <td>{item?.type == 'D' ?<span style={{color:'red', fontSize:'20px'}}>-</span> : <span style={{color:'green', fontSize:'20px'}}>+</span>}  <b style={{color:item?.type == 'D' ? 'red': 'green'}}>$ {item?.amount}</b></td>
                                    </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>
                </div>
        
            </div>
         </div>
   </>
  )
}

export default EarningHistory