import React, { useEffect, useRef, useState } from 'react'
import LoginHeaderTwo from '../common/LoginHeaderTwo'
import { IMAGE } from '../../../utils/Theme'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Wk from './Wk';
import { NavLink, useLocation, useNavigate, useParams  } from 'react-router-dom';
import ApiConnection, { BaseUrl } from '../../../utils/ApiConnection';
import Loader from '../../../utils/Loader';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { GetTeampreviewAction } from '../../../redux/reducer/fccDataflowreducer';
import moment from 'moment';




const CreateTeam = () => {
    const [loading, setloading] = useState(false)
    const [gamerule, setgamerule] = useState({})
    const [wkList, setwkList] = useState([])
    const [selectwkList, setselectwkList] = useState([])
    const [batList, setbatList] = useState([])
    const [selectbatList, setselectbatList] = useState([])
    const [arList, setarList] = useState([])
    const [selectarList, setselectarList] = useState([])
    const [bowlList, setbowlList] = useState([])
    const [selectbowlList, setselectbowlList] = useState([])
    const [tabs, settabs] = useState("wk")
    const [teams, setteams] = useState([])
    const [match, setmatch] = useState("")
    const [timer, setTimer] = useState("00:00:00");
    const [mineachside, setmineachside] = useState("")
    const [maxachside, setmaxachside] = useState("")
    const [teamonewk, setteamonewk] = useState([])
    const [teamtwowk, setteamtwowk] = useState([])
    const [teamonebat, setteamonebat] = useState([])
    const [teamtwobat, setteamtwobat] = useState([])
    const [teamonear, setteamonear] = useState([])
    const [teamtwoar, setteamtwoar] = useState([])
    const [teamonebow, setteamonebow] = useState([])
    const [teamtwobow, setteamtwobow] = useState([])
    const [fantasyCredit, setfantasyCredit] = useState(null)

    


    let dispatch = useDispatch()
    const navigate = useNavigate()
    const {id, pros} = useParams()
    const Ref = useRef(null);

    const statevalue = useLocation();

    var {fantacygameId, gameId, uniqueId} = statevalue?.state
 

    const GetPlayers = async ()=>{
        setloading(true)
        try {
            const  response = await ApiConnection.get(`member-fantasygame/get-game-players/${fantacygameId}`)
            const  responseone = await ApiConnection.get(`get-game-rules?game_admin=${gameId}&fantasygame_id=${fantacygameId}`)
          
            if(response?.status && responseone?.status  == 200){
                setloading(false)
                setgamerule(responseone.data?.data?.list?.game_rule)
                setmineachside(Math.round(responseone.data?.data?.list?.game_rule?.minimum_number_players_each_side))
                setmaxachside(Math.round(responseone.data?.data?.list?.game_rule?.maximum_number_players_each_side))
                setfantasyCredit(responseone.data?.data?.fantasygame?.players_total_credit_required)
                setbatList(response.data?.data?.list?.players?.Batsman)
                setwkList(response.data?.data?.list?.players?.WicketKeeper)
                setarList(response.data?.data?.list?.players?.AllRounder)
                setbowlList(response.data?.data?.list?.players?.Bowler)
                var TempArr = []

   

                var finalarr = [...response.data?.data?.list?.players?.Batsman, ...response.data?.data?.list?.players?.WicketKeeper, ...response.data?.data?.list?.players?.AllRounder, ...response.data?.data?.list?.players?.Bowler]

                const unique = [...new Set(finalarr.map(item => item.player_team_id))];
               
                let Tempitem  = []
                for(let i=0;i<unique.length;i++) {
                    let item = finalarr.filter(item => item.player_team_id == unique[i])
                    Tempitem.push({
                    id: unique[i],
                    team_name:item[0].team_name,
                    data: item,
                    total_players:0
                  })
                }
                setteams(Tempitem)
             

                // for (var i = 0; i < finalarr.length; i++){
                //     var tmpArray = [];
                //     for (var j = i+1; j < finalarr.length; j++) {
                //         if(finalarr[i].player_team_id == finalarr[j].player_team_id){
                //             tmpArray.push({
                //                 name:finalarr[j].team_name
                //             });
                           
                //         }   
                //     }
                  

                //     TempBatsman.push(tmpArray.slice(i, 1));
                 
                // }

                // const unique = finalarr.map((item) => item.player_team_id)
                // .filter((value, index, self) => self.indexOf(value) === index);
                //     console.log(unique);

               // console.log(TempBatsman)

                // finalarr.forEach(element => {
                //     finalarr.forEach(innerelement =>{

                //     })
                // })
              
                // response.data?.data?.list?.teams.forEach(element => {

                //     TempArr.push({
                //         'team_name':element?.team_name,
                //         'player_team_id':element?.team_id,
                //         'total_players':0
                //     })
                // });


                
                setmatch(response.data?.data?.list?.matchs)
            } else{
                setloading(false)
            }  
        } catch(err){
            setloading(false)
        }
    }
 

    useEffect(() => {
        GetPlayers()
   
    },[])


    const wkhandleRowClicked = (row) => {

        var max = Math.round(gamerule?.maximum_number_wicket_keepers)

        const updatedData = wkList.map(item => {
        
          if (row.id !== item.id) {
            return item
          }
    
          return {
            ...item,
            toggleSelected: !item.toggleSelected
          };
        });
        var TempArr = []
        updatedData.forEach(element =>{
            if(element?.toggleSelected){
                TempArr.push(element);
            }
        })
        setselectwkList(TempArr)

        if(TempArr.length > max){
            toast.error(`You choose maximum ${max} wicket keeper`)
        } else {
            setwkList(updatedData);
        }

       
        let teama = []
        let teamb = []

            TempArr.forEach(item => {
                if(item.player_team_id == teams[0].id){
                    teama.push(item)
                }
            })

            TempArr.forEach(item => {
                if(item.player_team_id == teams[1].id){
                    teamb.push(item)
                }
            })

            setteamonewk(teama)
            setteamtwowk(teamb)
      
    

      };

      const bathandleRowClicked = (row) => {

        var max = Math.round(gamerule?.maximum_number_batsman)
        var min = Math.round(gamerule?.minimum_number_batsman)

        const updatedData = batList.map(item => {
        
          if (row.id !== item.id) {
            return item
          }
    
          return {
            ...item,
            toggleSelected: !item.toggleSelected
          };
        });
        var TempArr = []
        updatedData.forEach(element =>{
            if(element?.toggleSelected){
                TempArr.push(element);
            }
        })

        setselectbatList(TempArr)

        

        if(TempArr.length > max){
            toast.error(`You choose maximum ${max} batsman`)
        }  else {
            setbatList(updatedData);
        }

         
        let teama = []
        let teamb = []

            TempArr.forEach(item => {
                if(item.player_team_id == teams[0].id){
                    teama.push(item)
                }
            })

            TempArr.forEach(item => {
                if(item.player_team_id == teams[1].id){
                    teamb.push(item)
                }
            })

            setteamonebat(teama)
            setteamtwobat(teamb)
      };
    
      const arhandleRowClicked = (row) => {

        var max = Math.round(gamerule?.maximum_number_all_rounders)

        const updatedData = arList.map(item => {
        
          if (row.id !== item.id) {
            return item
          }
    
          return {
            ...item,
            toggleSelected: !item.toggleSelected
          };
        });
        var TempArr = []
        updatedData.forEach(element =>{
            if(element?.toggleSelected){
                TempArr.push(element);
            }
        })
        setselectarList(TempArr)
   
        if(TempArr.length > max){
            toast.error(`You choose maximum ${max} all rounders`)
        } else {
            setarList(updatedData);
        }

        let teama = []
        let teamb = []

            TempArr.forEach(item => {
                if(item.player_team_id == teams[0].id){
                    teama.push(item)
                }
            })

            TempArr.forEach(item => {
                if(item.player_team_id == teams[1].id){
                    teamb.push(item)
                }
            })

            setteamonear(teama)
            setteamtwoar(teamb)


      };
      const bowlhandleRowClicked = (row) => {

        var max = Math.round(gamerule?.maximum_number_bowlers)

        const updatedData = bowlList.map(item => {
        
          if (row.id !== item.id) {
            return item
          }
    
          return {
            ...item,
            toggleSelected: !item.toggleSelected
          };
        });
        var TempArr = []
        updatedData.forEach(element =>{
            if(element?.toggleSelected){
                TempArr.push(element);
            }
        })
        setselectbowlList(TempArr)
   
        if(TempArr.length > max){
            toast.error(`You choose maximum ${max} bowlers`)
        } else {
            setbowlList(updatedData);
        }
        let teama = []
        let teamb = []

            TempArr.forEach(item => {
                if(item.player_team_id == teams[0].id){
                    teama.push(item)
                }
            })

            TempArr.forEach(item => {
                if(item.player_team_id == teams[1].id){
                    teamb.push(item)
                }
            })

            setteamonebow(teama)
            setteamtwobow(teamb)
  

      };

const NextPageHandle = async () => {

    var totalplayer = selectwkList.length + selectbatList.length + selectarList.length + selectbowlList.length

    var minwk =  Math.round(gamerule?.minimum_number_wicket_keepers)
    var minbat = Math.round(gamerule?.minimum_number_batsman)
    var minar = Math.round(gamerule?.minimum_number_all_rounders)
    var minbowl = Math.round(gamerule?.minimum_number_bowlers)
   // selectbowlList.length >= minbowl
   if(totalplayer < 11){
    toast.error('Please choose minimum 11 players')
   }
   else {


    if(selectwkList.length >= minwk && selectbatList.length >= minbat && selectarList.length >= minar)
    {
        setloading(true)
        try{
            
            var FormData = require('form-data');
            var data = new FormData();
    
            selectwkList.map((list, i) =>{
                return   data.append('wicket_keeper[]', list.id);
            })

            selectbatList.map((list, i) =>{
                return   data.append('batsman[]', list.id);
            })

            selectarList.map((list, i) =>{
                return   data.append('all_rounder[]', list.id);
            })

            selectbowlList.map((list, i) =>{
                return   data.append('bowler[]', list.id);
            })
          
            data.append("fantasygame_id", fantacygameId);
            data.append("team_unique_id", uniqueId);
            
        
            const response = await ApiConnection.post("member-fantasygame", data);
            if(response.status === 200){
                setloading(false)
                toast.success(response?.data?.message);
                 navigate("/select-captain", { state: { id: response.data?.data?.team_unique_id, fantacyId:fantacygameId} })
            } else {
                setloading(false)
            }

        } catch(err){
            setloading(false)
            if(err?.response?.status === 422){
                  toast.error(err.response?.data?.message);   
              
            }
    }
  
    } else {
       toast.error('Please select minimum player')
    }

}


   
}


const TeampreviewHandle = ()=>{
    var minwk =  Math.round(gamerule?.minimum_number_wicket_keepers)
    var minbat = Math.round(gamerule?.minimum_number_batsman)
    var minar = Math.round(gamerule?.minimum_number_all_rounders)
    var minbowl = Math.round(gamerule?.minimum_number_bowlers)
   // selectbowlList.length >= minbowl


    if(selectwkList.length >= minwk && selectbatList.length >= minbat && selectarList.length >= minar)
    {
    let data = {
       wicket:selectwkList,
       bat: selectbatList,
       ar:selectarList,
       bowl:selectbowlList,
       teams:teams
    }
    dispatch(GetTeampreviewAction(data))
    window.open('/team-preview', '_blank');

} else {
    toast.error('Please select minimum player')
}
    
}




const getTimeRemaining = (e) => {
    const total =
        Date.parse(e) - Date.parse(match?.match_date);
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor(
        (total / 1000 / 60) % 60
    );
    const hours = Math.floor(
        (total / 1000 / 60 / 60) % 24
    );
    return {
        total,
        hours,
        minutes,
        seconds,
    };
};

const startTimer = (e) => {
    let { total, hours, minutes, seconds } =
        getTimeRemaining(e);
    if (total >= 0) {
        // update the timer
        // check if less than 10 then we need to
        // add '0' at the beginning of the variable
        setTimer(
            (hours > 9 ? hours : "0" + hours) +
            ":" +
            (minutes > 9
                ? minutes
                : "0" + minutes) +
            ":" +
            (seconds > 9 ? seconds : "0" + seconds)
        );
    }
};
const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("00:00:10");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
        startTimer(e);
    }, 1000);
    Ref.current = id;
};
const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 10);
    return deadline;
};
useEffect(() => {
    clearTimer(getDeadTime());
}, []);

const onClickReset = () => {
    clearTimer(getDeadTime());
};

function GetTotalcreditscore(){

    let sumbat = 0;

    selectbatList.forEach( num => {
        sumbat += num?.credit_score;
})

let sumwk = 0;

selectwkList.forEach( num => {
    sumwk += num?.credit_score;
})

let sumar = 0;

selectarList.forEach( num => {
    sumar += num?.credit_score;
})

let sumbowl = 0;

selectbowlList.forEach( num => {
    sumbowl += num?.credit_score;
})

var total = sumbat + sumwk + sumar + sumbowl

var leftr = fantasyCredit - total

return leftr;

}




  return (
    <>
      {loading && <Loader/>}
    <LoginHeaderTwo  heading="Create a Team"  />
    <div className='back-page'>
        <div className='container'>
            <button onClick={() => navigate(-1)}><i className="fas fa-arrow-left"></i> Back</button>
           
        </div>
    </div>
    <div className='team-container'>
    <div className='container'>
        <div className='row align-items-center'>
            <div className='col-lg-3'>
                <h5>Player</h5>
                <h6>{selectwkList.length + selectbatList.length + selectarList.length + selectbowlList.length}/11</h6>
            </div>
            <div className='col-lg-6'>
                <div className='row justify-content-center'>
                <div className='col-lg-4'>
                                <div className='team-cnt'>
                                  
                                    <div className='team-cnt-info'>
                                        <h4>{teams[0]?.team_name.substring(0,3)}</h4>
                                        <b>{teamonewk.length + teamonebat.length + teamonear.length + teamonebow.length}</b>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='team-cnt'>
                                  
                                    <div className='team-cnt-info'>
                                        <h4>{teams[1]?.team_name.substring(0,3)}</h4>
                                        <b>{teamtwowk.length + teamtwobat.length + teamtwoar.length + teamtwobow.length}</b>
                                    </div>
                                </div>
                            </div>
                    
                  
            </div>
               
            </div>
            <div className='col-lg-3 text-right'>
                <h5>Credits Left</h5>
                <h6>{GetTotalcreditscore()}</h6>
            </div>
        </div>
        <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
        <span>ODI Match</span>
    </div>
    </div>
    <div className='team-info'>
        <div className='container'>
            <ul>
                <li>Match Date: <b>{moment(match?.match_date).format('MMM DD YY')}</b></li>
                <li>Match Time: <b>{moment(`2024-01-01 ${match?.match_start_time}`).format('LT')}</b></li>
            </ul>
        </div>
    </div>
    <div className='container'>
            <div className='inner-container'>

                <ul className='team-tabs'>
                    <li className='nav-item'>
                        <button onClick={()=>settabs('wk')} className={tabs === 'wk' ? 'nav-link active' : 'nav-link'}>{`WK (${selectwkList.length})`}</button>
                    </li>
                    <li className='nav-item'>
                        <button onClick={()=>settabs('bat')}  className={tabs === 'bat' ? 'nav-link active' : 'nav-link'}>{`bat (${selectbatList.length})`}</button>
                    </li>
                    <li className='nav-item'>
                        <button onClick={()=>settabs('ar')}    className={tabs === 'ar' ? 'nav-link active' : 'nav-link'}>{`AR (${selectarList.length})`}</button>
                    </li>
                    <li className='nav-item'>
                        <button onClick={()=>settabs('bowl')}   className={tabs === 'bowl' ? 'nav-link active' : 'nav-link'}>{`BOWL (${selectbowlList.length})`}</button>
                    </li>
                </ul>
                
                <div className='table-style'>
                        {tabs === "wk" &&
                        <>
                        <div className='tab-info'>
                            <h5>Pick 1-{wkList&&wkList.length} Wicket Keeper:</h5>
                            <h6>Minimum: {Math.round(gamerule?.minimum_number_wicket_keepers)}  |  Maximum: {Math.round(gamerule?.maximum_number_wicket_keepers)}</h6>
                        </div>
                             <div className='table-responsive'>
            <table className='custom-table creatTeamTable'>
                <thead>
                    <th >Team </th>
                    <th >Points </th>
                    {/* <th >Sell By </th> */}
                    <th >Credits </th>
                </thead>
                <tbody>
                    {wkList&&wkList.map((row, i)=>{
                        return (
                            <tr onClick={()=>wkhandleRowClicked(row)} key={i} className={row.toggleSelected ? "selected" : ''}>
                                 <td>
                                    <div className='team-td'>
                                        <img src={BaseUrl.baseurl + row?.player_image} />
                                        <span>{row?.team_short_name}</span>
                                    </div>
                                 </td>
                                 <td>
                                 <div className='point-part'>
                                    <h3>{row?.player_name}</h3>
                                    {/* <h5>200 points</h5>
                                    <h6>Played Last Match</h6> */}
                                </div>
                                </td>
                                {/* <td>80%</td> */}
                                <td>{row?.credit_score}</td>
                                <td>{row.toggleSelected ? <i className="far fa-times-circle text-danger"></i>  : <i className="fas fa-plus-circle"></i>}</td>
                             </tr>
                        )
                    })}
                    
                </tbody>
            </table>
                                                  </div>
                        
                      
                             <div className='text-center mt-4'>
                             <ul className='tableBtn'>
                              
                                 <li>
                                     <button className='solid-btn' onClick={()=>{
                                        if(Math.round(selectwkList.length < gamerule?.minimum_number_wicket_keepers ))
                                        toast.error(`Please select minimum ${Math.round(gamerule?.minimum_number_wicket_keepers)}`)
                                            
                                        else settabs('bat')
                                     }}>Next</button>
                                 </li>
                             </ul>
                     </div>
                     </>
                        }
                         {tabs === "bat" &&
                          <>
                          <div className='tab-info'>
                              <h5>Pick 1-{batList&&batList.length} Batsman:</h5>
                              <h6>Minimum: {Math.round(gamerule?.minimum_number_batsman)}  |  Maximum: {Math.round(gamerule?.maximum_number_batsman)}</h6>
                          </div>
                               <div className='table-responsive'>
              <table className='custom-table creatTeamTable'>
                  <thead>
                      <th >Team </th>
                      <th >Points </th>
                      <th >Credits </th>
                  </thead>
                  <tbody>
                      {batList&&batList.map((row, i)=>{
                          return (
                              <tr onClick={()=>bathandleRowClicked(row)} key={i} className={row.toggleSelected ? "selected" : ''}>
                                   <td>
                                      <div className='team-td'>
                                          <img src={BaseUrl.baseurl + row?.player_image} />
                                          <span>{row?.team_short_name}</span>
                                      </div>
                                   </td>
                                   <td>
                                   <div className='point-part'>
                                      <h3>{row?.player_name}</h3>
                                      {/* <h5>200 points</h5>
                                      <h6>Played Last Match</h6> */}
                                  </div>
                                  </td>
                                  <td>{row?.credit_score}</td>
                                  <td>{row.toggleSelected ? <i className="far fa-times-circle text-danger"></i>  : <i className="fas fa-plus-circle"></i>}</td>
                               </tr>
                          )
                      })}
                      
                  </tbody>
              </table>
                                                    </div>
                          
                        
                               <div className='text-center mt-4'>
                               <ul className='tableBtn'>
                               <li>
                                <button className='outline-btn' onClick={()=>settabs('wk')}>Prev</button>
                          
                            </li>
                               <li>
                                     <button className='solid-btn' onClick={()=>{
                                        if(Math.round(selectbatList.length < gamerule?.minimum_number_batsman ))
                                        toast.error(`Please select minimum ${Math.round(gamerule?.minimum_number_batsman)}`)
                                            
                                        else settabs('ar')
                                     }}>Next</button>
                                 </li>
                               </ul>
                       </div>
                       </>
                        } 
                       {tabs === "ar" &&
                         <>
                         <div className='tab-info'>
                             <h5>Pick 1-{arList&&arList.length} all rounders:</h5>
                             <h6>Minimum: {Math.round(gamerule?.minimum_number_all_rounders)}  |  Maximum: {Math.round(gamerule?.maximum_number_all_rounders)}</h6>
                         </div>
                              <div className='table-responsive'>
             <table className='custom-table creatTeamTable'>
                 <thead>
                     <th >Team </th>
                     <th >Points </th>
                     <th >Credits </th>
                 </thead>
                 <tbody>
                     {arList&&arList.map((row, i)=>{
                         return (
                             <tr onClick={()=>arhandleRowClicked(row)} key={i} className={row.toggleSelected ? "selected" : ''}>
                                  <td>
                                     <div className='team-td'>
                                         <img src={BaseUrl.baseurl + row?.player_image} />
                                         <span>{row?.team_short_name}</span>
                                     </div>
                                  </td>
                                  <td>
                                  <div className='point-part'>
                                     <h3>{row?.player_name}</h3>
                                     {/* <h5>200 points</h5>
                                     <h6>Played Last Match</h6> */}
                                 </div>
                                 </td>

                                 <td>{row?.credit_score}</td>
                                 <td>{row.toggleSelected ? <i className="far fa-times-circle text-danger"></i>  : <i className="fas fa-plus-circle"></i>}</td>
                              </tr>
                         )
                     })}
                     
                 </tbody>
             </table>
                                                   </div>
                         
                       
                              <div className='text-center mt-4'>
                              <ul className='tableBtn'>
                              <li>
                                <button className='outline-btn' onClick={()=>settabs('bat')}>Prev</button>
                          
                            </li>
                              <li>
                                    <button className='solid-btn' onClick={()=>{
                                       if(Math.round(selectarList.length < gamerule?.minimum_number_all_rounders ))
                                       toast.error(`Please select minimum ${Math.round(gamerule?.minimum_number_all_rounders)}`)
                                           
                                       else settabs('bowl')
                                    }}>Next</button>
                                </li>
                              </ul>
                      </div>
                      </>
                        } 
                       
                         {tabs === "bowl" &&

<>
<div className='tab-info'>
    <h5>Pick 1-{bowlList&&bowlList.length} bowlers:</h5>
    <h6>Minimum: {Math.round(gamerule?.minimum_number_bowlers)}  |  Maximum: {Math.round(gamerule?.maximum_number_bowlers)}</h6>
</div>
     <div className='table-responsive'>
<table className='custom-table creatTeamTable'>
<thead>
<th >Team </th>
<th >Points </th>
<th >Credits </th>
</thead>
<tbody>
{bowlList&&bowlList.map((row, i)=>{
return (
    <tr onClick={()=>bowlhandleRowClicked(row)} key={i} className={row.toggleSelected ? "selected" : ''}>
         <td>
            <div className='team-td'>
                <img src={BaseUrl.baseurl + row?.player_image} />
                <span>{row?.team_short_name}</span>
            </div>
         </td>
         <td>
         <div className='point-part'>
            <h3>{row?.player_name}</h3>
            {/* <h5>200 points</h5>
            <h6>Played Last Match</h6> */}
        </div>
        </td>
        <td>{row?.credit_score}</td>
        <td>{row.toggleSelected ? <i className="far fa-times-circle text-danger"></i>  : <i className="fas fa-plus-circle"></i>}</td>
     </tr>
)
})}

</tbody>
</table>
                          </div>


     <div className='text-center mt-4'>
     <ul className='tableBtn'>
     <li>
                                <button className='outline-btn' onClick={()=>settabs('ar')}>Prev</button>
                          
                            </li>
                            <li>
                            <button className='outline-btn' onClick={TeampreviewHandle}>Team Preview</button>
             
                          
                            </li>
                            <li>
                                <button className='solid-btn' onClick={NextPageHandle}>Select captain</button>
                            </li>
     </ul>
</div>
</>
                     
                       
                        } 
                    </div>
          
              
            </div>
    </div>
    </>
  )
}

export default CreateTeam